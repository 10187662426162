export const navigation = [
  {
    text: 'Attività',
    path: '/home',
    icon: 'home'
  },
  /*
  {
    text: 'Examples',
    icon: 'folder',
    items: [
      {
        text: 'Profile',
        path: '/profile'
      },
      {
        text: 'Tasks',
        path: '/tasks'
      }
    ]
  }, 
  */
  {
    text: 'Clienti',
    path: '/customers',
    icon: 'group'
  }, 
  {
    text: 'Mandati',
    path: '/mandates',
    icon: 'box'
  }, 
  {
    text: 'Tipi Incarico',
    path: '/tasktypes',
    icon: 'tags'
  }, 
  {
    text: 'Reports',
    path: '/reports',
    icon: 'chart'
  },
  {
    text: 'Consulenti',
    path: '/consultants',
    icon: 'globe'
  }
  // , 
  // {
  //   text: 'Utenti',
  //   path: '/users',
  //   icon: 'folder'
  // }
  ];

  export const navigationOperator = [
    {
      text: 'Attività',
      path: '/home',
      icon: 'home'
    }
    ];
