import defaultUser from '../utils/default-user';

/*
async function login(email, password) {

  const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Auth/Login'
  
  
  const payload = {
    Email: email,
    Password: password,
    //RememberMe: rememberMe
  };
  
  return fetch(serviceUrl, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload),
  })
  .then((response) => response.json())
  .then(user => {
    if (user) {
      user.avatarUrl = '../../img/user-profile-icon.png';
      localStorage.setItem('ac-user', JSON.stringify(user));
      return user;
    } else {
      return null;
    }
  });
  
}
*/

export async function loggedUser(email, name) {

  const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Auth/FindUser'
  
  const payload = {
    Email: email,
    Password: name,
    //RememberMe: rememberMe
  };
  
  return fetch(serviceUrl, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
  .then((response) => response.json())
  .then(user => {
    if (user) {
      console.log("LOGGEDUSERID... " + user.userId);
      return user;
    } else {
      return null;
    }
  });

}

export async function signIn(email, password) {
  try {
    // Send request
    console.log(email, password);

    //let userLogin = await login(email, password)

    return {
      isOk: true,
      data: defaultUser
      //data: userLogin
    };
  }
  catch {
    return {
      isOk: false,
      message: "Autenticazione fallita"
    };
  }
}


export async function getUser() {
  try {
    // Send request
    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}


/*
export async function getUser() {  
  try {
    // Send request
    let user = JSON.parse(localStorage.getItem('ac-user'));

    //console.log("getUser", getUser);

    if (user) {
      return {
        isOk: true,
        data: user
      };
    } else {
      return {
        isOk: false,
        data: null
      };
    }
  }
  catch {
    return {
      isOk: false
    };
  }
}
*/
export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
