import React from 'react';
import './customers.scss';

import { createStore } from 'devextreme-aspnet-data';
import DataGrid, {
  Column,
  //Button,
  Pager,
  Paging,
  FilterRow,
  MasterDetail,
  Lookup,
  Editing,
  Popup,
  Form,
  Selection,
  Toolbar,
  Item,
  Summary,
  GroupItem,
  TotalItem,
  SearchPanel
} from 'devextreme-react/data-grid';

import { useState, useEffect } from "react";
//import {Toolbar, Item} from 'devextreme-react/toolbar';

import customermandates from './customermandates.js';
import { CheckBox } from 'devextreme-react/check-box';
const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Cliente'



export default function Customers() {

  const [categorie, setCategorie] = useState({});
  const [isHideClosed, setIsHideClosed] = useState(false);

  const filterPayload = { 
    hideClosed: isHideClosed
  };


  const remoteDataSource = createStore({
    key: 'id',
    //loadUrl: serviceUrl + '/GetAction',
    loadUrl: serviceUrl + '/Find',

    onBeforeSend: (method, ajaxOptions) => {
      if (method == "load" ) {           
          var payload = ajaxOptions.data;
          payload.values = JSON.stringify(filterPayload);
          ajaxOptions.data = payload;          
      }  
      //ajaxOptions.headers = { Authorization: `Bearer ${user.token}`}   
     ;
    },
    insertUrl: serviceUrl + '/InsertAction',
    updateUrl:serviceUrl + '/UpdateAction',
    deleteUrl: serviceUrl + '/DeleteAction',
  
    errorHandler: (error) => {
      console.log("ERRORE:" + error.message);
    }
    
  });

  useEffect(() => {

    const newServiceUrl1 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/CategoriaIncarico';
    var newUrl1 =  newServiceUrl1 + "/GetAction";

    const fetchData = async () => {
      try {
        const response1 = await fetch(newUrl1);
        const json1 = await response1.json();
        console.log(json1);
        setCategorie(json1);

      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  function onCheckValueChanged(e)  {
    setIsHideClosed(e.value);
    //console.log("Nuovo Valore Check:" + e.value);
  };

  const addButtonOptions = {
    icon: 'plus',
    text: 'Nuovo Cliente'
    // onClick: () => {
    //   notify('Add button has been clicked!');
    // },
  };

  function onToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.showText = "always";
        item.options.hint = " Nuovo Cliente";
      }
    });
  }

return (
  <React.Fragment>
    <h2 className={'content-block'}>Clienti</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        
      <DataGrid
        dataSource={remoteDataSource}
        focusedRowEnabled={true}
        onToolbarPreparing = {onToolbarPreparing}
      >
        <SearchPanel visible={true} width={240} placeholder="Cerca..." />
        <Toolbar>
          <Item name="searchPanel" location="before"/>
          <Item location="after" name="addRowButton"
          options={addButtonOptions}
          >
          </Item>
          <Item location="after">
            <CheckBox  
              value={isHideClosed} onValueChanged={onCheckValueChanged}
              defaultValue={isHideClosed}
              text="  NASCONDI CHIUSI   "
            >
            </CheckBox>
          </Item>    
         </Toolbar>
        <Selection mode="single" />
        <Editing
          mode="popup"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}     
        >
           <Popup   title= "Cliente " showTitle={true} width={600} height={525} />
          <Form>
            <Item itemType="group" caption="" colCount={2} colSpan={2}>
         
              <Item dataField="nome" colSpan={2}/>
              <Item dataField="tipoIntermediario" colSpan={2}/> 
              <Item dataField="categoriaId" /> 
              <Item dataField="chiuso" 
                editorType="dxCheckBox"/>
            </Item>   
          </Form> 
        </Editing>   
        <Column dataField="id" visible={false}/>
        <Column dataField="nome" width={480}/>
        <Column dataField="tipoIntermediario" caption = "Tipo Intermediario"/>
        <Column dataField="chiuso" caption = "Chiuso"/>
        <Column dataField="categoriaId" caption="Categoria" width={160}>
          <Lookup dataSource={categorie} displayExpr="nome" valueExpr="id" />
        </Column>

        <MasterDetail
          enabled={true}
          component={customermandates}
        />
      </DataGrid>  

      </div>
    </div>
  </React.Fragment>
)
};
