import React from 'react';
import './tasktypes.scss';

import { createStore } from 'devextreme-aspnet-data';
import DataGrid, {
  Column,
  //Button,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Lookup,
  Editing,
  Popup,
  Form,
  Selection,
  Toolbar,
  Item,
  Summary,
  GroupItem,
  TotalItem,
  SearchPanel
} from 'devextreme-react/data-grid';

import { useState, useEffect } from "react";
//import {Toolbar, Item} from 'devextreme-react/toolbar';

const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/TipoIncarico'

const remoteDataSource = createStore({
  key: 'id',
  loadUrl: serviceUrl + '/GetAction',
  insertUrl: serviceUrl + '/InsertAction',
  updateUrl:serviceUrl + '/UpdateAction',
  deleteUrl: serviceUrl + '/DeleteAction'
  
});

export default function TaskTypes() {

  const [categorie, setCategorie] = useState({});

  useEffect(() => {

    const newServiceUrl1 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/CategoriaIncarico';
    var newUrl1 =  newServiceUrl1 + "/GetAction";

    const fetchData = async () => {
      try {
        const response1 = await fetch(newUrl1);
        const json1 = await response1.json();
        console.log(json1);
        setCategorie(json1);

      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);


  function onToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.showText = "always";
        item.options.text = "Nuovo Tipo Incarico";
        item.options.hint = "Nuovo Tipo Incarico";
      }
    });
  }

  return (  
  <React.Fragment>
    <h2 className={'content-block'}>Tipi Incarico</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
       
      <DataGrid
        dataSource={remoteDataSource}
        focusedRowEnabled={true}
        onToolbarPreparing = {onToolbarPreparing}
        >
         <SearchPanel visible={true} width={240} placeholder="Cerca..." />
         <Toolbar>
          <Item name="searchPanel" location="before"/>
          <Item location="after" name="addRowButton">
          </Item>
         </Toolbar>
        <Selection mode="single" />
        <HeaderFilter visible={true} />
        <Editing
          mode="popup"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}     
        >
           <Popup   title= "Tipo Incarico " showTitle={true} width={600} height={525} />
          <Form>
            <Item itemType="group" caption="" colCount={2} colSpan={2}>
         
              <Item dataField="nome" colSpan={2}/>
              <Item dataField="categoriaId" colSpan={2}/> 
            </Item>   
          </Form> 
        </Editing>   
        <Column dataField="id" visible={false}/>
        <Column dataField="nome" />
        <Column dataField="categoriaId" caption="Categoria" >
          <Lookup dataSource={categorie} displayExpr="nome" valueExpr="id" />
        </Column>
      </DataGrid>  

      </div>
    </div>
  </React.Fragment>
)
};

