import React from 'react';
import './reports.scss';

import { useRef,useState, useEffect,useCallback} from "react";
import { Logout, mesi, IsRole_Manager, IsRole_Operator } from '../../globals';

import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import { DateBox } from 'devextreme-react/date-box';

import { Button } from 'devextreme-react/button';

import { createStore } from 'devextreme-aspnet-data';
import DataGrid, {
  Column,
  //Button,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Editing,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Toolbar,
  Item,
  Summary,
  GroupItem,
  TotalItem,
  SearchPanel
} from 'devextreme-react/data-grid';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import { useAuth } from  '../../contexts/auth'; 

export default function Reports() {

  var reportDataGridRef = React.createRef();

  const [clienteId, setClienteId] = useState();
  const [mandatoId, setMandatoId] = useState();
  const [utenteId, setUtenteId] = useState();
  const [tipoIncaricoId, setTipoIncaricoId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startDateMonth, setStartDateMonth] = useState();
  const [startDateYear, setStartDateYear] = useState();
  const [dettaglio, setDettaglio] = useState(false);
  const [raggruppa, setRaggruppa] = useState(false);

  const [clienti, setClienti] = useState({});
  const [mandati, setMandati] = useState({});
  const [tipiIncarico, setTipiIncarico] = useState({});
  const [utenti, setUtenti] = useState({});
  const [anni, setAnni] = useState({});

  //autenticazione
  const { user } = useAuth(); 

  if (!user) 
    Logout();



  var filterPayload = { 
    idCliente: clienteId,
    idMandato: mandatoId,
    idUser: utenteId,
    idTipoIncarico: tipoIncaricoId,
    fromDate: startDate,
    toDate: endDate,
    month: startDateMonth,
    year: startDateYear,
    detail: dettaglio,
  };

  const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/View_Report'

  const remoteDataSource = createStore({
    key: 'id',
    loadUrl: serviceUrl + '/Find',

    
    onBeforeSend: (method, ajaxOptions) => {
      if (method == "load" ) {           
          var payload = ajaxOptions.data;
          filterPayload.idMandato = mandatoId;
          filterPayload.idTipoIncarico = tipoIncaricoId;
          filterPayload.idUser = utenteId;
          filterPayload.year = startDateYear;
          filterPayload.month = startDateMonth;
          filterPayload.fromDate = startDate;
          filterPayload.toDate = endDate;
          filterPayload.detail = dettaglio;
          //filterPayload.currView = currentView;
          payload.values = JSON.stringify(filterPayload);
        
          ajaxOptions.data = payload;      
      } 
      //ajaxOptions.headers = { Authorization: `Bearer ${user.token}`}    
    },

    onLoaded: function (result) {
      console.log(result)  
    }


  });

  useEffect(() => {

    const newServiceUrl1 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Cliente';
    var newUrl1 =  newServiceUrl1 + "/GetAction";

    const newServiceUrl2 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Mandato';
    var newUrl2 =  newServiceUrl2 + "/GetAction";

    const newServiceUrl3 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/TipoIncarico';
    var newUrl3 =  newServiceUrl3 + "/GetAction";

    const newServiceUrl4 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Users';
    var newUrl4 =  newServiceUrl4 + "/GetAction";

    const newServiceUrl5 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/View_Report';
    var newUrl5 =  newServiceUrl5 + "/GetYears";

    const fetchData = async () => {
      try {
        const response1 = await fetch(newUrl1);
        const json1 = await response1.json();
        console.log(json1);
        setClienti(json1);

        const response2 = await fetch(newUrl2);
        const json2 = await response2.json();
        console.log(json2);
        setMandati(json2);

        const response3 = await fetch(newUrl3);
        const json3 = await response3.json();
        console.log(json3);
        setTipiIncarico(json3);

        const response4 = await fetch(newUrl4);
        const json4 = await response4.json();
        console.log(json4);
        setUtenti(json4);

        const response5 = await fetch(newUrl5);
        const json5 = await response5.json();
        console.log(json5);
        setAnni(json5);


      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();

    //mandatiDataSource = mandatiRef.current.instance.getDataSource();

  }, []);


  const clienteValueChanged = (e) => {
    setClienteId(e.value);
    setMandatoId(null);
  }

  const mandatoValueChanged = (e) => {
    setMandatoId(e.value);
  };

  const tipoIncaricoValueChanged = (e) => {
    setTipoIncaricoId(e.value);
  };

  const utenteValueChanged = (e) => {
    setUtenteId(e.value);
  };

  const annoValueChanged = (e) => {
    setStartDateYear(e.value);
  };

  const meseValueChanged = (e) => {
    setStartDateMonth(e.value);
  };

  const startDateValueChanged = (e) => {
    setStartDate(e.value);
  };
  const endDateValueChanged = (e) => {
    setEndDate(e.value);
  };

  const dettaglioValueChanged = (e) => {
    setDettaglio(e.value);
    reportDataGridRef.instance.columnOption("userName", "visible", e.value);
  };

  const raggruppaValueChanged = (e) => {
    setRaggruppa(e.value);
  };

  function onClickReset() {
    setClienteId(null);
    setMandatoId(null);
    setUtenteId(null);
    setTipoIncaricoId(null);
    setStartDate(null);
    setEndDate(null);
    setStartDateMonth(null);
    setStartDateYear(null);
  };

  function onMandatoOpened(e) {
    e.component.getDataSource().filter(["clienteId", "=", clienteId]);
    e.component.getDataSource().reload();
    console.log('CLIENTEID:'+  clienteId)
    console.log('MANDATOID:'+  mandatoId)
  }

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Report.xlsx');
      });
    });
    e.cancel = true;
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
        widget: 'dxButton',
        name: "myexport",
        location: "after",
        options: {
            icon: "xlsxfile",
            text: "Esporta in Excel",
            onClick: function() {
              onExporting(e);
            }
        }
    });
}

  function LinearDatagrid()
  {
    return(
    <DataGrid
        dataSource={remoteDataSource}
        //focusedRowEnabled={true}
        onExporting={onExporting}
        ref={ref => reportDataGridRef = ref}
        sorting={false}
        onToolbarPreparing = {onToolbarPreparing}
        >
          <Toolbar></Toolbar>
          <GroupPanel visible={false} />
        
          <Selection mode="single" />
          <Editing
            mode="popup"
            useIcons={true}
            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}     
          >
          
          </Editing>   
          <Column dataField="id" visible={false}/>
          <Column dataField="cliente" />
          <Column dataField="mandato" />
          <Column dataField="tipoIncarico" />
          <Column dataField="userName" caption = "Consulente" visible={dettaglio}/>
          <Column dataField="note" visible={dettaglio}/>
          <Column dataField="startDate" caption = "Data Attività" dataType={'date'} format= {'dd/MM/yyyy'} visible={dettaglio}/>
          <Column dataField="smart" visible={dettaglio} caption = "Smart Work." alignment='center' width={150}/>
          <Column dataField="hours" caption = "Ore Attività "width={150}/>
          <Column dataField="orePreviste" width={150} alignment='right'/>
          <Column dataField="chiuso" caption = "Chiuso" width={150} alignment='center'/>

          <Export enabled={false} allowExportSelectedData={false} />
          
        </DataGrid>
    )
  }

  function GroupedDatagrid()
  {
    return(

      <DataGrid
        dataSource={remoteDataSource}
        //focusedRowEnabled={true}
        onExporting={onExporting}
        ref={ref => reportDataGridRef = ref}
        sorting={false}
        onToolbarPreparing = {onToolbarPreparing}
        >
        <Toolbar></Toolbar>
        <GroupPanel visible={false} />
        <Grouping autoExpandAll={true} />
        <Selection mode="single" />
        <Editing
          mode="popup"
          useIcons={true}
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}     
        >
         
        </Editing>   
        <Column dataField="id" visible={false}/>
        <Column dataField="cliente" groupIndex={0}/>
        <Column dataField="cliente2" caption = "Cliente"/>
        <Column dataField="mandato" />
        <Column dataField="tipoIncarico" />
        <Column dataField="userName" caption = "Consulente" visible={dettaglio}/>
        <Column dataField="note" visible={dettaglio}/>
        <Column dataField="startDate" caption = "Data Attività" dataType={'date'} format= {'dd/MM/yyyy'} visible={dettaglio}/>
        <Column dataField="smart" visible={dettaglio} caption = "Smart Work." alignment='center' width={150}/>
        <Column dataField="hours" caption = "Ore Attività "width={150}/>
        <Column dataField="orePreviste" width={150} alignment='right'/>
        <Column dataField="chiuso" caption = "Chiuso" width={150} alignment='center'/>

        <Summary>
        <GroupItem
        column="mandato"
          summaryType="max"
          displayFormat="TOTALE: "
          showInGroupFooter={true} 
       />
          <GroupItem
              column="hours"
              summaryType="sum"
              displayFormat="{0}"
              showInGroupFooter
          />
          
          {
          !dettaglio &&
          <GroupItem
              column="orePreviste"
              summaryType="sum"
              displayFormat="{0}"
              showInGroupFooter
          />
          }
        </Summary>
        <Export enabled={false} allowExportSelectedData={false} />

      </DataGrid>  
    )
  }


  return (
  <React.Fragment>
    <h2 className={'content-block'}>Reports</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
      
      <div class="groupfilterTitle"><b>Filtra per</b></div>

      <div class="groupfilter">
        <div class="filter-field-label"><b>Cliente</b></div>
        <div class="filter-field">
          <SelectBox
              dataSource={clienti}
              valueExpr="id"
              displayExpr="nome"
              value = {clienteId}
              showClearButton={true}
              onValueChanged={clienteValueChanged}
              searchEnabled={true}
              //defaultValue={defaultUserId}
          />
        </div>
        <div class="filter-field-label"><b>Mandato</b></div>
        <div class="filter-field">
          <SelectBox
               // ref={mandatiRef}
              //ref={ref => mandatiRef = ref}
              //id="selectMandato"
              dataSource={mandati}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={mandatoValueChanged}
              value = {mandatoId}
              showClearButton={true}
              onOpened= {onMandatoOpened}
              //defaultValue={defaultUserId}
          />
        </div>

        <div class="filter-field-label"><b>Anno</b></div>
        <div class="filter-field">
          <SelectBox
              dataSource={anni}
              valueExpr="id"
              displayExpr="anno"
              value = {startDateYear}
              showClearButton={true}
              onValueChanged={annoValueChanged}
          />
        </div>
        <div class="filter-field-label"><b>Mese</b></div>
        <div class="filter-field">
          <SelectBox
              dataSource={mesi}
              valueExpr="codice"
              displayExpr="desc"
              value = {startDateMonth}
              showClearButton={true}
              onValueChanged={meseValueChanged}
          />
        </div>

        <div class="filter-field-label"><b>Tipo Incarico</b></div>
        <div class="filter-field">
          <SelectBox
              dataSource={tipiIncarico}
              valueExpr="id"
              displayExpr="nome"
              value = {tipoIncaricoId}
              showClearButton={true}
              onValueChanged={tipoIncaricoValueChanged}
              //defaultValue={defaultUserId}
          />
        </div>
        <div class="filter-field-label"><b>Consulente</b></div>
        <div class="filter-field">
          <SelectBox
              dataSource={utenti}
              valueExpr="id"
              displayExpr="name"
              value = {utenteId}
              showClearButton={true}
              onValueChanged={utenteValueChanged}
              //defaultValue={defaultUserId}
          />
        </div>


       
        <div class="filter-field-label"><b>Dalla Data</b></div>
        <div class="filter-field">
          <DateBox
                //defaultValue={true}
                value = {startDate}
                showClearButton={true}
                onValueChanged={startDateValueChanged}
          />
        </div>
        <div class="filter-field-label"><b>alla Data</b></div>
        <div class="filter-field">
          <DateBox
                //defaultValue={true}
                value = {endDate}
                showClearButton={true}
                onValueChanged={endDateValueChanged}
          />
        </div>
      </div>


      <div>
        <div class="dettaglio-field-label"><b>Dettaglio</b></div>
        <div class="dettaglio-field">
          <CheckBox
                defaultValue={true}
                value = {dettaglio}
                onValueChanged={dettaglioValueChanged}
          />
        </div>

        <div class="dettaglio-field-label"><b>Raggruppa</b></div>
        <div class="dettaglio-field">
          <CheckBox
                defaultValue={true}
                value = {raggruppa}
                onValueChanged={raggruppaValueChanged}
          />
        </div>

        <div class="button-reset" float= "right">
          <Button 
            text="Annulla Filtri"
            type="normal"
            stylingMode="outlined"
            onClick={onClickReset}
          />
        </div>
      </div>

      {
        !raggruppa &&
      <LinearDatagrid></LinearDatagrid>
      }
      {
        raggruppa &&
      <GroupedDatagrid></GroupedDatagrid>  
      }

      </div>
    </div>
 
  </React.Fragment>
)};
