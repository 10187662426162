import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, loggedUser, signIn as sendSignInRequest } from '../api/auth';


function AuthProvider(props) {

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  var account = '';
 
  if (props.instance)
  {
    const msalInstance = props.instance;  
    account = msalInstance.getAllAccounts()[0];
    if (account) {
      console.log("MS ACCOUNTNAME... " + account.username);
      console.log("MS ACCOUNT... " +JSON.stringify(account.idTokenClaims));
    }
  }


  useEffect(() => {
    (async function () {
      const result = await getUser();
      const result2 = await loggedUser(account.username,account.name);
      if (result.isOk && result2) {

        // AZURE MSAL
        result.data.email = account.username;
        result.data.avatarUrl = "";
        result.data.roles = account.idTokenClaims.roles;
        result.data.name=account.idTokenClaims.name;
        console.log("ROLES... " + JSON.stringify(result.data));

        //LOCAL USER TABLE
        console.log("LOGGEDUSER... " + JSON.stringify(result2));
        result.data.userId=result2.userId;
        result.data.username=result2.username;
        
        setUser(result.data);
      }
   
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password) => {

    console.log("SIGN IN");
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
    }

    return result;
  }, []);

  
  const signOut = useCallback(() => {
    setUser(undefined);
    if (props.instance)
    {
      console.log("LOGOUT... " + account.username);
      const msalInstance = props.instance;
      msalInstance.logoutRedirect();
    }
  }, []);


/*
  const signOut = useCallback(() => {
    localStorage.removeItem('ac-user');
    setUser(undefined);
    //setUser();
  }, []);
*/

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
