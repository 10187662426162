import { useAuth } from  './contexts/auth'; 

//export const criticitaOptions =  [{id:1,desc: 'Critico'},{id:2,desc: 'Non critico'}];

export const mesi =  [{codice:'1',desc: 'Gennaio'},{codice:'2',desc: 'Febbraio'},{codice:'3',desc: 'Marzo'},{codice:'4',desc: 'Aprile'},{codice:'5',desc: 'Maggio'},{codice:'6',desc: 'Giugno'},
{codice:'7',desc: 'Luglio'},{codice:'8',desc: 'Agosto'},{codice:'9',desc: 'Settembre'},{codice:'10',desc: 'Ottobre'},{codice:'11',desc: 'Novembre'},{codice:'12',desc: 'Dicembre'}
];

export function Logout() {
    localStorage.removeItem('ac-user');
    window.location.reload();
}

export function IsRole_Manager() {
    const { user } = useAuth(); 
    //const isRole = user.roles.includes("MANAGER");
    console.log("IS_MANAGER: " + JSON.stringify(user));
    const isRole = user.roles.includes("Task.Admin");
    console.log("IS_MANAGER_TEST: " + isRole);
    return isRole;
}
export function IsRole_Operator() {
    const { user } = useAuth(); 
    //const isRole = user.roles.includes("OPERATOR");
    const isRole = user.roles.includes("Task.User");
    console.log("IS_OPERATOR_TEST: " + isRole);
    
    return isRole;
}