import { HomePage, TasksPage, ProfilePage, CustomersPage, MandatesPage, TasktypesPage, UsersPage, ConsultantsPage, ReportsPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    }, 
  {
    path: '/customers',
    element: CustomersPage
  }, 
  {
    path: '/mandates',
    element: MandatesPage
  }, 
  {
    path: '/tasktypes',
    element: TasktypesPage
  }, 
  {
    path: '/users',
    element: UsersPage
  }, 
  {
    path: '/consultants',
    element: ConsultantsPage
  }, 
  {
    path: '/reports',
    element: ReportsPage
  }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
