import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
//import 'devextreme/dist/css/dx.material.additionalblue.css';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';

import itMessages from "devextreme/localization/messages/it.json"; 
import { locale, loadMessages } from "devextreme/localization";

// AZURE MSAL
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from "@azure/msal-react";
import NotAuthContent from './NotAuthContent';

function App() {

  loadMessages(itMessages);  
  locale("it");

  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {

  const screenSizeClass = useScreenSizeClass();
  const { instance} = useMsal();

  if (instance)
  {
    const msalInstance = instance;
    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      console.log("APP... " + account.username);
    }
  }

  return (
    <Router>
      <AuthenticatedTemplate>
        <AuthProvider instance={instance}>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </AuthProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <NotAuthContent instance={instance}/>
      </UnauthenticatedTemplate>
    </Router>
  );
}
