import React from 'react';
import './users.scss';

import { createStore } from 'devextreme-aspnet-data';
import DataGrid, {
  Column,
  //Button,
  MasterDetail,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Editing,
  Popup,
  Form,
  Item,
  Selection,
  SearchPanel
} from 'devextreme-react/data-grid';

//import { useState, useEffect } from "react";
//import {Toolbar, Item} from 'devextreme-react/toolbar';

import roles from './roles.js';

const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Users'

const remoteDataSource = createStore({
  key: 'id',
  loadUrl: serviceUrl + '/GetAction',
  insertUrl: serviceUrl + '/InsertAction',
  updateUrl:serviceUrl + '/UpdateAction',
  deleteUrl: serviceUrl + '/DeleteAction'
  
});

export default function Users() {
  return (  
  <React.Fragment>
    <h2 className={'content-block'}>Utenti</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        
      <DataGrid
        dataSource={remoteDataSource}
        focusedRowEnabled={true}
        >
         <SearchPanel visible={true} width={240} placeholder="Cerca..." />
        <Selection mode="single" />
        <Editing
          mode="popup"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}     
        >
           <Popup   title= "Utente " showTitle={true} width={600} height={525} />
          <Form>
            <Item itemType="group" caption="" colCount={2} colSpan={2}>
         
              <Item dataField="name" />
              <Item dataField="email" /> 
              <Item dataField="username" />
              <Item dataField="password" /> 
            </Item>   
          </Form> 
        </Editing>   
        <Column dataField="id" visible={false}/>
        <Column dataField="name" caption="Nome"/>
        <Column dataField="email" visible={false}/>
        <Column dataField="username" />
        <Column dataField="password" visible={false}/>

        <MasterDetail
          enabled={true}
          component={roles}
        />
      </DataGrid>  

      </div>
    </div>
  </React.Fragment>
)
};
