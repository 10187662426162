import React from 'react';
import './mandates.scss';

import Query from 'devextreme/data/query';
import CustomStore from "devextreme/data/custom_store";

import { createStore } from 'devextreme-aspnet-data';
import DataGrid, {
  Column,
  //Button,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Lookup,
  Editing,
  Popup,
  Form,
  Selection,
  Toolbar,
  Item,
  Summary,
  GroupItem,
  TotalItem,
  SearchPanel
} from 'devextreme-react/data-grid';

import { useRef, useState, useEffect, useCallback } from "react";
//import {Toolbar, Item} from 'devextreme-react/toolbar';
import { SimpleItem} from "devextreme-react/form";
import { CheckBox } from 'devextreme-react/check-box';

const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Mandato'




export default function Mandates() {

  var mySelectBoxRef = useRef();//React.createRef();

  const [clienti, setClienti] = useState({});
  const [tipiIncarico, setTipiIncarico] = useState({});
  const [isHideClosed, setIsHideClosed] = useState(false);

  const filterPayload = { 
    hideClosed: isHideClosed
  };

  const remoteDataSource = createStore({
    key: 'id',
    //loadUrl: serviceUrl + '/GetAction',
    loadUrl: serviceUrl + '/Find',

    onBeforeSend: (method, ajaxOptions) => {
      if (method == "load" ) {           
          var payload = ajaxOptions.data;
          payload.values = JSON.stringify(filterPayload);
          ajaxOptions.data = payload;          
      }  
      //ajaxOptions.headers = { Authorization: `Bearer ${user.token}`}   
     ;
    },

    insertUrl: serviceUrl + '/InsertAction',
    updateUrl:serviceUrl + '/UpdateAction',
    deleteUrl: serviceUrl + '/DeleteAction',
    
    onLoaded: function (result) {
      console.log(result)  
    },
    errorHandler: (error) => {
      console.log("ERRORE:" + error.message);
    }
  });

  var currCategoriaId = "";

  function getClienteById(id) {
    return Query(clienti).filter(['id', id]).toArray()[0];
  }




  const getFilteredTipiIncarico = useCallback(
    (options) => {
      
      return {
        key: "id",
        store: new CustomStore({
          load: function (loadOptions) {
            // fake call server to get the list of article
            return new Promise((resolve) => {
              setTimeout(() => {
                const res = loadOptions.filter
                  ? tipiIncarico.filter((c) => c.clienteId === options.data.clienteId)
                  : tipiIncarico;
                resolve(res);
              }, 500);                
            });
          },
          byKey: function (key) {
            return new Promise((resolve) => {
              resolve(tipiIncarico.find((c) => c.incaricoId === key));
            });
          }
        }),
        filter: options.data ? ["clienteId", "=", options.data.clienteId] : null
      };
    },
    [tipiIncarico]
  );

/*
  const getFilteredTipiIncarico = useCallback(
    (options) => {
      
      return {
        key: "id",
        store: new CustomStore({
          load: function (loadOptions) {
            // fake call server to get the list of article
            return new Promise((resolve) => {
              setTimeout(() => {
                const res = loadOptions.filter
                  ? tipiIncarico.filter((c) => c.categoriaId === currCategoriaId)
                  : tipiIncarico;
                resolve(res);
              }, 500);                
            });
          },
          byKey: function (key) {
            return new Promise((resolve) => {
              resolve(tipiIncarico.find((c) => c.id === key));
            });
          }
        }),
        filter: options.data ? ["categoriaId", "=", currCategoriaId] : null
      };
    },
    [tipiIncarico]
  );
*/

  function setClienteValue(rowData, value) {
    rowData.tipoIncaricoId = null;
    //var clienteInfo= getClienteById(value);
    //currCategoriaId = clienteInfo.categoriaId;
    this.defaultSetCellValue(rowData, value);
  }


  useEffect(() => {

    const newServiceUrl1 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Cliente';
    var newUrl1 =  newServiceUrl1 + "/GetAction";

    //const newServiceUrl2 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/TipoIncarico';
    const newServiceUrl2 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/TipoIncaricoCliente';
    var newUrl2 =  newServiceUrl2 + "/GetAction";

    const fetchData = async () => {
      try {
        const response1 = await fetch(newUrl1);
        const json1 = await response1.json();
        console.log(json1);
        setClienti(json1);

        const response2 = await fetch(newUrl2);
        const json2 = await response2.json();
        console.log(json2);
        setTipiIncarico(json2);

      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);


  function onCheckValueChanged(e)  {
    setIsHideClosed(e.value);
    //console.log("Nuovo Valore Check:" + e.value);
  };

  function onToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.showText = "always";
        item.options.text = "Nuovo Mandato";
        item.options.hint = "Nuovo Mandato";
      }
    });
  }

  return (
  <React.Fragment>
    <h2 className={'content-block'}>Mandati</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        
      <DataGrid
        dataSource={remoteDataSource}
        focusedRowEnabled={true}
        onToolbarPreparing = {onToolbarPreparing}
        >
         <SearchPanel visible={true} width={240} placeholder="Cerca..." />
         <Toolbar>
          <Item name="searchPanel" location="before"/>
          <Item location="after" name="addRowButton">
          </Item>
          <Item location="after">
            <CheckBox  
              value={isHideClosed} onValueChanged={onCheckValueChanged}
              defaultValue={isHideClosed}
              text="  NASCONDI CHIUSI   "
            >
            </CheckBox>
          </Item>    
         </Toolbar>

        <Selection mode="single" />
        <HeaderFilter visible={true} />
        <Editing
          mode="popup"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}     
        >
          <Popup   title= "Mandato " showTitle={true} width={600} height={625} />
          <Form 
            id="frmMandate" 
            //onFieldDataChanged={onFieldDataChanged}
          >
            <Item itemType="group" caption="" colCount={2} colSpan={2}>
         
              <Item dataField="clienteId" colSpan={2}/> 
              <Item dataField="nome" colSpan={2}/>
              <Item dataField="tipoIncaricoId" colSpan={2}  ref={mySelectBoxRef} /> 
              <Item dataField="orePreviste" />
              <Item dataField="chiuso" 
                editorType="dxCheckBox"/>
            </Item>   
          </Form> 
        </Editing>   
        <Column dataField="id" visible={false}/>
  
        
        <Column dataField="nome" caption = "Nome" width={280}/>

        <Column dataField="tipoIncaricoId" caption="Tipo Incarico" >
          <Lookup dataSource={getFilteredTipiIncarico} displayExpr="incarico" valueExpr="incaricoId" />
        </Column>

        <Column dataField="clienteId" caption="Cliente"  setCellValue={setClienteValue}>
          <Lookup dataSource={clienti} displayExpr="nome" valueExpr="id" />
        </Column>

        <Column dataField="orePreviste" />
        <Column dataField="chiuso" caption = "Chiuso"/>
      </DataGrid>  

      </div>
    </div>
  </React.Fragment>
)
};
