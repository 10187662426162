import React from 'react';
//import Query from 'devextreme/data/query';
//import {formatDate} from 'devextreme/localization';

//function getMovieById(id) {
//  return Query(moviesData).filter(['id', id]).toArray()[0];
//}

export default function Appointment(model) {
  const { targetedAppointmentData } = model.data;

  return (

       
    <div className="showtime-preview">
      <div>{targetedAppointmentData.cliente}</div>
      <div>{targetedAppointmentData.mandato}</div>
      <div>{targetedAppointmentData.hours} ore</div>

    </div>

  );
}