//import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
//import routes from './app-routes';
//import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';

import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import './NoAuthContent.scss';
//import UserPanel from '../user-panel/UserPanel';

import logo from './img/logo_negativo.png';

export default function Content(props) {

  const login = async () => {
    await props.instance.loginRedirect();
  };

  const renderBack = () => {
    return <div id="back">
      <img
        alt={''}
        width="60%"
        height="auto"
      src={logo}
    />
    </div>;
  }

  return (
    <div>

      <Toolbar className={'header-toolbar'}>
          <Item
            visible={true}
            location={'before'}
            widget={'dxButton'}
            cssClass={'menu-button'}
          >
            <Button icon="menu" stylingMode="text" 
            //onClick={toggleMenu} 
            />
          </Item>
           
          <Item
            location="before"
            render={renderBack}
            ></Item>
          <Item
            location={'after'}
            locateInMenu={'auto'}
            menuItemTemplate={'userPanelTemplate'}
          >
            <Button
              className={'user-button authorization'}
              width={210}
              height={'100%'}
              stylingMode={'text'}
              text=''
            >
            
            </Button>
          </Item>
      
    
        
        </Toolbar>
        <h3 className={'content-block-noauth'}>Login</h3>
        
            <div className={'block-container-noauth'}>
              <h5>Per proseguire è necessario effettuare l'autenticazione.</h5>
              <Button onClick={login}
              width={210}
              type="success"
              >Login con Office 365</Button>
            </div>
      

      
    </div>
  );
}

