import React from 'react';
import './users.scss';


import { createStore } from 'devextreme-aspnet-data';
import DataGrid, {
  Column,
  //Button,
  Lookup,
  Editing,
  Popup,
  Form,
  Item
} from 'devextreme-react/data-grid';

import { useState, useEffect } from "react";
const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Roles'



export default function Roles(props) {

    const myKey = props.data.key;
    const [ruoli, setRuoli] = useState({});
    const [userId, setUserId] = useState();

    const filterPayload = { 
        idUser: myKey
      };

    const remoteDataSource = createStore({
        key: 'id',
        loadUrl: serviceUrl + '/FindUserRoles',
        insertUrl: serviceUrl + '/AddUserRole',
        deleteUrl: serviceUrl + '/RemoveUserRole',
    
        onBeforeSend: (method, ajaxOptions) => {
            if (method == "load" ) {           
                var payload = ajaxOptions.data;
                //filterPayload.idUser = utenteId;
                payload.values = JSON.stringify(filterPayload);
                
                ajaxOptions.data = payload;      
            } 
            //ajaxOptions.headers = { Authorization: `Bearer ${user.token}`}    
        },
    
        onLoaded: function (result) {
        console.log(result)  
        }

    });

   

    useEffect(() => {

        const newServiceUrl1 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Roles';
        var newUrl1 =  newServiceUrl1 + "/GetAction";
    
    
        const fetchData = async () => {
          try {
            const response1 = await fetch(newUrl1);
            const json1 = await response1.json();
            console.log(json1);
            setRuoli(json1);
    
          } catch (error) {
            console.log("error", error);
          }
        };
        fetchData();
    
        //mandatiDataSource = mandatiRef.current.instance.getDataSource();
    
    }, []);

    async function setUserValue(newData, value, currentRowData) {

        //console.log("user: " + props.data.key)
        newData.roleId = value;
        newData.userId= props.data.key;
    }


    return (  
        <React.Fragment>
            <div class="rolesTitle">Ruoli</div>
            <div className={'content-block'}>
                
                <DataGrid
                    dataSource={remoteDataSource}
                    focusedRowEnabled={true}
                    width="30%"
                    showColumnHeaders={false}
                    >
                    <Editing
                        mode="row"
                        useIcons={true}
                        allowAdding={true}
                        allowDeleting={true}
                        allowUpdating={false}     
                    ></Editing>
                    <Column dataField="id" visible={false}/>
                    <Column dataField="userId" caption="User"  visible={false}></Column>
                    <Column dataField="roleId" caption="Ruolo" setCellValue={setUserValue}>
                        <Lookup dataSource={ruoli} displayExpr="name" valueExpr="id" />
                    </Column>
                </DataGrid>  
            </div>
            
        </React.Fragment>
    )
};