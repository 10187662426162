import React from 'react';
import './consultants.scss';

import Scheduler, { Editing, Resource } from 'devextreme-react/scheduler';
import { createStore } from 'devextreme-aspnet-data';
import CustomStore from "devextreme/data/custom_store";

import { useRef,useState, useEffect,useCallback} from "react";

import Query from 'devextreme/data/query';

import appointment from '../home/appointment';

import SelectBox from 'devextreme-react/select-box';

import { Logout, IsRole_Manager, IsRole_Operator } from '../../globals';

import { useAuth } from  '../../contexts/auth'; 
import notify from 'devextreme/ui/notify';

const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Consulenza'
const serviceUrl2 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/View_Consulenza'

const categories = [
  { id: 1, text: 'Cliente', color: '#3f93c4' },
  { id: 2, text: 'Interno', color: '#08b363' }
];

var currentDate = new Date();
const views = ['day', 'workWeek', 'month'];
var currentView = "workWeek";


export default function Consultants() {


  const [clienti, setClienti] = useState({});
  const [mandati, setMandati] = useState({});
  const [mandatiAttivi, setMandatiAttivi] = useState({});
  const [tipiIncarico, setTipiIncarico] = useState({});
  const [utenti, setUtenti] = useState({});
  const [utenteId, setUtenteId] = useState();
  const [mandatoId, setMandatoId] = useState();
  const [mandatiUtente, setMandatiUtente] = useState({});

  const schedulerStates = {allowDragging: false, allowResizing: false};
 
  var isOperator = false;
  var isManager = false;
  var defaultUserId= null;
  const mandatiRef = useRef();
 

  
  //autenticazione
  const { user } = useAuth(); 

  //var mandatiRef = React.createRef();

  if (!user) 
    Logout();
  else
  {
   // console.log('Token:' + user.token);
    console.log('Username:' + user.username);
    console.log('ID:' + user.userId);
    isManager =IsRole_Manager();
    isOperator =IsRole_Operator();
    if (isOperator)
      defaultUserId = user.userId;
    else
      defaultUserId = utenteId;

    console.log('defaultUserId:' + defaultUserId);
    console.log('isManager:' + isManager);
  
  } 

  var filterPayload = { 
    idUser: defaultUserId,
    roleManager: isManager,
    currDate: currentDate,
    currView: currentView,
    idMandato: mandatoId
  };
  
  const remoteDataSource = createStore({
    key: 'id',
    loadUrl: serviceUrl2 + '/Find',
    insertUrl: serviceUrl + '/InsertAction',
    updateUrl:serviceUrl + '/UpdateAction',
    deleteUrl: serviceUrl + '/DeleteAction',
    
    onBeforeSend: (method, ajaxOptions) => {
      if (method == "load" ) {           
          var payload = ajaxOptions.data;
          filterPayload.currDate = currentDate;
          filterPayload.currView = currentView;
          payload.values = JSON.stringify(filterPayload);
         
          ajaxOptions.data = payload;   
          //console.log('CURRENT DATE ' + currentDate);      
          //console.log('CURRENT VIEW ' + currentView);     
      } 
      //ajaxOptions.headers = { Authorization: `Bearer ${user.token}`}    
    },
  
    onLoaded: function (result) {
      console.log(result)  
    }


  });

  useEffect(() => {

    const newServiceUrl1 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Cliente';
    var newUrl1 =  newServiceUrl1 + "/GetAction";

    const newServiceUrl2 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Mandato';
    var newUrl2 =  newServiceUrl2 + "/GetAction";

    const newServiceUrl3 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/TipoIncarico';
    var newUrl3 =  newServiceUrl3 + "/GetAction";

    const newServiceUrl4 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Users';
    var newUrl4 =  newServiceUrl4 + "/GetAction";

    const newServiceUrl5 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Mandato';
    var newUrl5 =  newServiceUrl5 + "/GetAttivi";

    const newServiceUrl6 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/View_MandatiByUser';
    var newUrl6 =  newServiceUrl6 + "/GetAction";

    const fetchData = async () => {
      try {
        const response1 = await fetch(newUrl1);
        const json1 = await response1.json();
        console.log(json1);
        setClienti(json1);

        const response2 = await fetch(newUrl2);
        const json2 = await response2.json();
        console.log(json2);
        setMandati(json2);

        const response3 = await fetch(newUrl3);
        const json3 = await response3.json();
        console.log(json3);
        setTipiIncarico(json3);

        const response4 = await fetch(newUrl4);
        const json4 = await response4.json();
        console.log(json4);
        setUtenti(json4);

        const response5 = await fetch(newUrl5);
        const json5 = await response5.json();
        console.log(json5);
        setMandatiAttivi(json5);
        //setMandatiUtente(json5);

        const response6 = await fetch(newUrl6);
        const json6 = await response6.json();
        console.log(json6);
        setMandatiUtente(json6);

      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();

    //mandatiDataSource = mandatiRef.current.instance.getDataSource();

  }, []);


  function onOptionChanged(e) {
    if (e.name === 'currentDate') {
      currentDate =e.value;
    }
    if (e.name === 'currentView') {
      currentView =e.value;
    }
  }

  const userValueChanged = (e) => {
 
      //mandatiDataSource.filter(["userId", "=", e.value]);
      //mandatiDataSource.load();
      setUtenteId(e.value);
      setMandatoId(null);
  
  }

  const mandatoValueChanged = e => {
    setMandatoId(e.value);
  };

  function onValueChanged(e) {
    setUtenteId(e.value);
    setMandatoId(null);
    console.log(`The value is changed to: "${e.value}"`);
  }

  function onValueChanged2(e) {
    setMandatoId(e.value);
    console.log(`The value is changed to: "${e.value}"`);
  }

  function onOpened(e) {
    //setMandatoId(null);
    e.component.getDataSource().filter(["userId", "=", utenteId]);
    e.component.getDataSource().reload();
    console.log('UTENTEID:'+  utenteId)
    
  }
/*
  const getFilteredMandatiUtente = useCallback(
    (options) => {
      
      return {
        key: "id",
        store: new CustomStore({
          load: function (loadOptions) {
            // fake call server to get the list of article
            return new Promise((resolve) => {
              setTimeout(() => {
                const res = loadOptions.filter
                  ? mandatiUtente.filter((c) => c.userId === utenteId)
                  : mandatiUtente;
                resolve(res);
              }, 500);                
            });
          },
          byKey: function (key) {
            return new Promise((resolve) => {
              resolve(mandatiUtente.find((c) => c.id === key));
            });
          }
        }),
        filter: options.data ? ["userId", "=", utenteId] : null
      };
    },
    [mandati]
  );
*/


  function getMandatoById(id) {
    return Query(mandati).filter(['id', id]).toArray()[0];
  }

  function isNotEditingAppointment(e) {

    const startDate = new Date(e.appointmentData.startDate);
    const today = new Date();
    const ctrlDate = new Date(new Date(today).setDate(today.getDate() - 28))

    if (isManager)  
      return false;
    else if (isOperator) {
      if(startDate < ctrlDate)
        return true;
      else
        return false;
    }
  } 

  function validateAppointment(e) {

    if(e.appointmentData.userId == null)
    {   
      e.cancel = true;
      notify('Selezionare l\'utente owner dell\' attività', 'error', 3000);
      
    }
    else if(e.appointmentData.clienteId == null || e.appointmentData.mandatoId == null)
    {
      e.cancel = true;
      notify('Selezionare Cliente e Mandato', 'error', 3000);
    }
   
    else if(e.appointmentData.hours == null)
    {
      e.cancel = true;
      notify('Inserire il numero di ore', 'error', 3000);
    }
  };

  const onAppointmentAdding = (e) => {
    validateAppointment(e);
  };


  const onAppointmentClick = (e) => {
    e.cancel = isNotEditingAppointment(e);
  };

  const onAppointmentDblClick = (e) => {
    e.cancel = isNotEditingAppointment(e);
  };

  const onAppointmentUpdating = (e) => {
    if(e.newData.hours == null)
    {
      e.cancel = true;
      notify('Inserire il numero di ore', 'error', 3000);
    }
    else if(e.newData.startDate == null)
    {
      e.cancel = true;
      notify('Inserire la data', 'error', 3000);
    }
  };

  function  onAppointmentFormOpening(e) {
    
    setTimeout(() => {
      e.popup.option("maxWidth", 800);
      e.popup.option("width", 800);
    });

    let toolbarItems = e.popup.option("toolbarItems"); 
     toolbarItems.forEach(item=>{ 
       console.log(item);
       if(item.options && item.options.text === "Fatto")
           item.options.text =  "OK";
      })
       e.popup.option("toolbarItems", toolbarItems);
    
    const { form } = e;
    let mandatoInfo = getMandatoById(e.appointmentData.mandatoId) || {};
    var idCliente = e.appointmentData.clienteId;
    var changing = false;


    console.log('INIT CLIENTE: ' + e.appointmentData.clienteId);

    form.option('items', [
      {
        label: {
          text: 'Cliente',
        },
        editorType: 'dxSelectBox',
        dataField: 'clienteId',  
        isRequired: true,
        editorOptions: {
          items: clienti,
          displayExpr: 'nome',
          valueExpr: 'id', 
          searchEnabled: true,
          onValueChanged: function (e) {  
            //var form = $('#frmCorrectiveActions').dxForm('instance');  
            var secondEditor =  form.getEditor("mandatoId");  
            //secondEditor.getDataSource().filter(['clienteId', '=', e.value]);  
            secondEditor.getDataSource().filter([['clienteId', '=', e.value], "and", ['chiuso', '=', false]]); 
            secondEditor.getDataSource().load(); 
            console.log('CHANGE CLIENTE:' + idCliente);
            if (isOperator)
              form.getEditor("userId").option("value", defaultUserId); 
            else
              form.getEditor("userId").option("value", utenteId); 
            if (changing)
              form.updateData('mandatoId', null);
              
            if(idCliente == null) {
              ;
            } else {
              idCliente=e.value;
            }
            changing = false;
            
          },
          onOpened: function (e) { 
            changing = true;
            console.log('CHANGING:' + changing)
          },
        },
      },{       
        label: {
          text: 'Mandato',
        },
        editorType: 'dxSelectBox',
        dataField: 'mandatoId',  
        isRequired: true,
        editorOptions: {
          items: mandati,
          displayExpr: 'nome',
          valueExpr: 'id', 
          onValueChanged: function (e) {   
            mandatoInfo= getMandatoById(e.value)     
            if (mandatoInfo != null)
              form.updateData('tipoIncaricoId', mandatoInfo.tipoIncaricoId);
            else
              form.updateData('tipoIncaricoId',null)
          },
          onFocusIn: function (e) {
            console.log('ON CLIC CLIENTE ' + idCliente);
            
            if(idCliente != null)
            {
              var secondEditor =  form.getEditor("mandatoId");
              secondEditor.getDataSource().filter([['clienteId', '=', idCliente], "and", ['chiuso', '=', false]]);  
              //secondEditor.getDataSource().filter(['clienteId', '=', idCliente]);  
              secondEditor.getDataSource().load();
            }
          
          }    
        },
      },{       
        label: {
          text: 'Tipo Incarico',
        },
        editorType: 'dxSelectBox',
        dataField: 'tipoIncaricoId', 
        isRequired: true, 
        editorOptions: {
          items: tipiIncarico,
          displayExpr: 'nome',
          valueExpr: 'id', 
          readOnly: true,
        },
      },{
        label: {
          text: 'Data',
        },
        name: 'startDate',
        dataField: 'startDate',
        editorType: 'dxDateBox',
        isRequired: true,
        editorOptions: {
          width: '100%',
          type: 'datetime',
          
        }
      },{
        label: {
          text: 'Ore',
        },
        name: 'hours',
        dataField: 'hours',
        isRequired: true,
        editorOptions: {
          width: '50%' 
        }
      },{
        label: {
          text: 'Smart Working',
        },
        name: 'smartWorking',
        dataField: 'smartWorking',
        editorType: 'dxCheckBox',
        editorOptions: {
          width: '50%' 
        }
      },
      {
        label: {
          text: 'Note',
        },
        name: 'note',
        dataField: 'note',
        editorType: 'dxTextArea',
        editorOptions: {
          width: '210%' 
        }
      },
      {       
        label: {
          text: 'Utente',
          visible: false
        },
        editorType: 'dxSelectBox',
        dataField: 'userId',  
        editorOptions: {
          items: utenti,
          displayExpr: 'name',
          valueExpr: 'id',
          defaultValue: defaultUserId,
          visible: false
        },
      },
     
    ]);
  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Consulenti</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        
        {isManager &&
          <div>
            <div class="select-field-label"><b>Consulente</b></div>
            <div class="select-field">
              <SelectBox
                 
                  //id="selectUtente"
                  dataSource={utenti}
                  valueExpr="id"
                  displayExpr="name"
                  value = {utenteId}
                  showClearButton={true}
                  onValueChanged={userValueChanged}
                  defaultValue={defaultUserId}
              />
            </div>
            <div class="select-field-label"><b>Mandato</b></div>
            <div class="select-field">
              <SelectBox
                  //ref={mandatiRef}
                  //ref={ref => mandatiRef = ref}
                  //id="selectMandato"
                  dataSource={mandatiUtente}
                  valueExpr="mandatoId"
                  displayExpr="mandato"
                  onValueChanged={mandatoValueChanged}
                  value = {mandatoId}
                  showClearButton={true}
                  onOpened= {onOpened}
                  width={480}
                  //defaultValue={defaultUserId}
              />
            </div>
          </div>
        }

        <Scheduler
          timeZone="Europe/Berlin"
          dataSource={remoteDataSource}
          views={views}
          defaultCurrentView={currentView}
          defaultCurrentDate={currentDate}
          showCurrentTimeIndicator={false}
          //height={800}
          startDayHour={9}
          endDayHour={18}
          textExpr="Note"
          appointmentComponent={appointment}
          onAppointmentFormOpening={onAppointmentFormOpening}
          //onAppointmentFormCreating={onAppointmentFormCreating}
          onAppointmentAdding={onAppointmentAdding}
          onAppointmentUpdating={onAppointmentUpdating}
          onAppointmentClick={onAppointmentClick}
          onAppointmentDblClick={onAppointmentDblClick}
          //onContentReady={onContentReady}
          onOptionChanged={onOptionChanged}
          editing={schedulerStates}
          allDayPanelMode="hidden"
          >
            <Resource
              dataSource={clienti}
              fieldExpr="clienteId"
          />
           <Resource
            fieldExpr="categoriaId"
            dataSource={categories} 
          />
        </Scheduler>
          
        </div>
      </div>
    </React.Fragment>
)}
