import React from 'react';
import './customers.scss';


import { createStore } from 'devextreme-aspnet-data';
import DataGrid, {
  Column,
  //Button,
  Lookup,
  Editing,
  Popup,
  Form,
  Item
} from 'devextreme-react/data-grid';

import { useState, useEffect } from "react";
const serviceUrl = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Mandato'



export default function CustomerMandates(props) {

    const myKey = props.data.key;
    // const [ruoli, setRuoli] = useState({});
    // const [userId, setUserId] = useState();

    const [clienti, setClienti] = useState({});
    const [tipiIncarico, setTipiIncarico] = useState({});

    const filterPayload = { 
        idCliente: myKey
      };

    const remoteDataSource = createStore({
        key: 'id',
        loadUrl: serviceUrl + '/FindByCustomer',
        //insertUrl: serviceUrl + '/AddUserRole',
        //deleteUrl: serviceUrl + '/RemoveUserRole',
    
        onBeforeSend: (method, ajaxOptions) => {
            if (method == "load" ) {           
                var payload = ajaxOptions.data;
                //filterPayload.idUser = utenteId;
                payload.values = JSON.stringify(filterPayload);
                
                ajaxOptions.data = payload;      
            } 
            //ajaxOptions.headers = { Authorization: `Bearer ${user.token}`}    
        },
    
        onLoaded: function (result) {
        console.log(result)  
        }

    });

    useEffect(() => {

        const newServiceUrl1 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/Cliente';
        var newUrl1 =  newServiceUrl1 + "/GetAction";
    
        const newServiceUrl2 = process.env.REACT_APP_WEBAPP_DOMAIN + '/api/TipoIncarico';
        var newUrl2 =  newServiceUrl2 + "/GetAction";
    
        const fetchData = async () => {
          try {
            const response1 = await fetch(newUrl1);
            const json1 = await response1.json();
            console.log(json1);
            setClienti(json1);
    
            const response2 = await fetch(newUrl2);
            const json2 = await response2.json();
            console.log(json2);
            setTipiIncarico(json2);
    
          } catch (error) {
            console.log("error", error);
          }
        };
        fetchData();
      }, []);

    return (  
        <React.Fragment>
            <div class="mandatesTitle">Mandati</div>
            <div className={'content-block'}>
                
                <DataGrid
                    dataSource={remoteDataSource}
                    focusedRowEnabled={false}
                    width="80%"
                    showColumnHeaders={true}
                    >
                    <Editing
                        mode="row"
                        useIcons={true}
                        //allowAdding={true}
                        //allowDeleting={true}
                        //allowUpdating={false}     
                    ></Editing>
                    <Column dataField="id" visible={false}/>
  
                    <Column dataField="clienteId" caption="Cliente" width={480} visible={false}>
                        <Lookup dataSource={clienti} displayExpr="nome" valueExpr="id" />
                    </Column>
                    <Column dataField="nome" caption = "Nome"/>

                    <Column dataField="tipoIncaricoId" caption="Tipo Incarico" width={360}>
                        <Lookup dataSource={tipiIncarico} displayExpr="nome" valueExpr="id" />
                    </Column>

                    <Column dataField="orePreviste" />
                    <Column dataField="chiuso" caption = "Chiuso"/>
                </DataGrid>  
            </div>
            
        </React.Fragment>
    )
};